export default [
  {
    header: 'Home',
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon'
  },
  // {
  //   header: 'Customer',
  //   title: 'Customer',
  //   route: 'customer',
  //   sub: ['customer-add', 'customer-detail'],
  //   icon: 'UsersIcon'
  // },
  {
    header: 'Case',
    title: 'Case',
    route: 'case',
    sub: ['case-add', 'case-detail'],
    icon: 'FileTextIcon'
  },
  {
    header: 'Knowledge',
    title: 'Knowledge',
    route: 'knowledge',
    sub: ['knowledge-add', 'knowledge-detail'],
    icon: 'SunIcon'
  },
  {
    header: 'Dashboards & Reports',
    title: 'Dashboards & Reports',
    route: 'dashboardsReport',
    icon: 'ClipboardIcon'
  },
  {
    header: 'Report',
    title: 'Case Detail Report',
    icon: 'BookIcon',
    children: [
      {
        header: 'Case Detail Report',
        title: 'Case Detail Report',
        route: 'report-ticket-detail',
        icon: 'BookIcon'
      },
      {
        header: 'Case Summary By Agent',
        title: 'Case Summary By Agent',
        route: 'report-summary-by-agent',
        icon: 'BookIcon'
      },
      {
        header: 'Case Summary By Channel',
        title: 'Case Summary By Channel',
        route: 'report-summary-by-channel',
        icon: 'BookIcon'
      },
      {
        header: 'Case Summary By Case Type',
        title: 'Case Summary By Case Type',
        route: 'report-summary-by-case-type',
        icon: 'BookIcon'
      }
    ]
  },
  {
    header: 'Favorite Email',
    title: 'Favorite Email',
    route: 'favorite-email',
    icon: 'StarIcon'
  }
]
